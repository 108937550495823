<!--
 * @Author: your name
 * @Date: 2020-09-03 17:47:02
 * @LastEditTime: 2020-09-10 10:10:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \webd:\source\kylp-manage-system\src\components\page\default\index.vue
-->
<template>
  <div class="wrapper">
      首页
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {}
};
</script>
<style lang="less" scoped>
.wrapper{}
</style>